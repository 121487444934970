const loadGoogleMapsApi = require('load-google-maps-api');

class Map {
    static loadGoogleMapsApi() {
        return loadGoogleMapsApi({key: 'AIzaSyB65ZdSdIy15Z6yfHunhDUmc-AY21ro1E8'});
    }

    static createMap(googleMaps, mapElement) {

        let myLatLng = {lat: 48.720310, lng: 2.571500};

        let map = new googleMaps.Map(mapElement, {
            center: myLatLng,
            zoom: 15
        });

        new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: 'EARL MICHOUX'
        });

        return map;
    }
}

export {Map};

document.addEventListener("DOMContentLoaded", function () {
    let mapElement = document.getElementById('map');

    if (mapElement) {
        Map.loadGoogleMapsApi().then(function (googleMaps) {
            Map.createMap(googleMaps, mapElement);
        });
    }
});
